<script>
import {indicadorEstoque} from "@/components/defaults/indicadorestoque";
// import Multiselect from "vue-multiselect";

export default {
  components:{
   // Multiselect
  },
  props: {
      currentEmpresa: { type: Object },
      oldLocalEstoque: { type: Object },
      pessoas: { type: Array },
    },
  data() {
    return {
      vet_pesssoas: [],
      conta: {},
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0
      },
      listIndicadores: [],
      loader: {
        get: false
      },
      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas:[
        {
          id:1,
          nome:"plano 01"
        },
        {
          id:2,
          nome:"plano 02"
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      plano:{
        woner_id  : null,
        descricao : null,      
        natureza : null,      
        codigo_reduzido : null,
        classificacao  : null
      }
    }
  },
  created(){
    this.plano = this.oldLocalEstoque
    this.vet_pesssoas = this.pessoas;
    this.conta = this.oldLocalEstoque
  },
  mounted() {
    this.$emit('newTitle', 'Alterar Plano de Conta');
    this.localEstoque = this.oldLocalEstoque;
    this.listIndicadores = indicadorEstoque;
  },
  methods: {
    
    setPut() {
      this.conta.saldo = this.new_convert_srting_float(this.conta.saldo)
      this.$emit('doPut', this.conta);
    },
    abr_multiselect(valor) {
      console.log("entrar");
      if(valor == 1){
        this.show_multiselect = true
      }else {
        this.show_multiselect = false
      }
    },
    selecionar_plano(event){
      this.plano.woner_id = event.id
    },
    selecionar_tipos(event){
      this.plano.classificacao = event.id
    },
    seleciona_orientacoes(event){
      this.plano.natureza = event.id
    }
  },
}
</script>

<template>
<div class="card">
    <div class="card-body">
      <div class="containner">
        <div class="row">
          <div class="col-md-6">
            <label for="pessoa" class="form-label">Pessoa</label>
            <select
              class="form-control"
              v-model="conta.pessoa_id"
              @change="seleciona_produtos($event)"
            >
              <option value="-1">selecione um valor</option>
              <option
                v-for="(item, index) in vet_pesssoas"
                :key="index"
                :value="item.id"
              >
                {{ item.pes_apelido }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="pessoa" class="form-label">Saldo</label>
            <input
              type="text"
              class="form-control"
              v-model="conta.saldo"
              @input="formatInputFloat($event, conta, 'saldo')"
              placeholder="Selecione a pessoa"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <Label for="Data" class="form-label">Data Criação</Label>
            <input
              type="datetime-local"
              class="form-control"
              v-model="conta.data"
            />
          </div>
          <div class="col-md-6">
            <Label for="numero" class="form-label">Número</Label>
            <input
              type="text"
              class="form-control"
              v-model="conta.numero"
              placeholder="Digite"
            />
          </div>
        </div>
        <div class="row mt-4">
             <div class="col-md-12">
              <Label for="Obs" class="form-label">Observação</Label>
               <textarea v-model="conta.obs" class="form-control" cols="30" rows="10"></textarea>
             </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button
          
          class="btn btn-light"
          @click.prevent="setPut()"
        >
          Salvar
        </button>
       
      </div>
    </div>
  </div>
</template>